import { useState } from "react";
import React, { useRef } from "react";
import { Form } from "react-bootstrap";
import { IMaskInput } from "react-imask";
import emailjs from '@emailjs/browser';
import { toast } from "react-toastify";

const initialState = {
  name: "",
  email: "",
  message: "",
  phone: "",
  marceneiro: false,
  contatoWhatsapp: false
};

export const Contact = (props) => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(form.current);

    var formaContato = "";

    if(contatoWhatsapp){
      formaContato = "Sim"
    }

    const souMarceneiro = marceneiro ? "sim":"não"

    const data = {
          name: `${name}`,
          email: `${email}`,
          phone: `${phone}` ,
          marceneiro: `${souMarceneiro}`,
          formaContato: `${formaContato}`,
          message: `${message}`,
          data: new Date(),
    }

    emailjs.send(process.env.REACT_APP_SERVICE_KEY, process.env.REACT_APP_TEMPLATE_KEY, data, process.env.REACT_APP_EMAIL_PUBLIC_KEY)
      .then((result) => {
          clearState();
          toast.success("Obrigado por se cadastrar!!", {
            hideProgressBar: true,
            position: toast.POSITION.TOP_CENTER
          });
      }, (error) => {
          console.log(error.text);
      });
  };


  const [marceneiro,setMarceneiro] = useState(initialState.marceneiro)
  const [contatoWhatsapp,setContatowhatsapp] = useState(false)

  const [{ name, email, message,phone }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleChangeMarceneiro = (e) =>{
    setMarceneiro(e.target.checked);
  }
  const handleChangeContatoWhatsapp = (e) =>{
    setContatowhatsapp(e.target.checked);
  }
  const handleChangePhone = (v, ref) => {
    setState(
      (prevState) => ({ ...prevState, [ref.el.input.name]: v }));
  };


  const clearState = () => {
    setState({ ...initialState })
    setContatowhatsapp(false)
    setMarceneiro(false)
  };

  return (
    <div>
      {/* <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      /> */}
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>FICOU INTERESSADO, DEIXE SEU CONTATO:</h2>
              </div>
              <form ref={form} name="sentMessage" validate onSubmit={sendEmail}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        value={name}
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Nome"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <IMaskInput
                        value={phone}
                        type="text"
                        id="phone"
                        name="phone"
                        className="form-control"
                        
                        mask="(00) 00000-0000"
                        placeholder="Telefone"

                        required
                        onAccept={handleChangePhone}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        value={email}
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="message">Deixe aqui seu feedback:</label>
                  <textarea
                    value={message}
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Mensagem"
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>

                <div className="form-group">
                  <Form.Check 
                    type="checkbox" 
                    checked={marceneiro}
                    id="marceneiro"
                    name="marceneiro"
                    label="Sou marceneiro"
                    onChange={handleChangeMarceneiro}
                  />
                </div> 
                <div className="form-group">
                  <Form.Check 
                    required
                    type="checkbox" 
                    checked={contatoWhatsapp} 
                    id="contatoWhatsapp"
                    name="contatoWhatsapp"
                    label="Aceito receber informações por Email/Whatsapp"
                    onChange={handleChangeContatoWhatsapp}
                  />
                </div>
                <div id="success"></div>


                <button type="submit" className="btn btn-custom btn-lg">
                  Enviar
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Informações de contato</h3>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-whatsapp"></i> Telefones
                </span>
                <div className="col-md-12">
                  <a href="https://api.whatsapp.com/send?phone=5554981648561">(54) 98164-8561</a>
                </div>
                <div className="col-md-12">
                  <a href="https://api.whatsapp.com/send?phone=+5545988409951">(45) 98840-9951</a>
                </div>
              </p>
            </div>
            <br/>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>
                <div className="col-md-12">
                  sejamontame@gmail.com
                </div>
              </p>
            </div>
          </div>
          {/* <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
