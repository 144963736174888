import React from "react";

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div id="title" className="col-md-12 section-title">
          <h1>Nossas soluções</h1>
        </div>
        
        <div id="cliente" className="row">
          <div className="col-md-12">
            <h2>para o cliente</h2>
          </div>
          <div className="col-xs-6 col-md-4">
            <i className="fa fa-home"></i>
            <h3>Desenhe seu Móvel</h3>
            <p>Utilizando módulos pré definidos, é possível criar vários móveis, e edita-los de forma fácil e intuitiva.</p>
          </div>
          <div className="col-xs-6 col-md-4">
            <i className="fa fa-star-half-o"></i>
            <h3>Escolhe seu marceneiro</h3>
            <p>Com um sistema de rankeamento, é possível escolher o marceneiro que melhor atenda as suas necessidades.</p>
          </div>
          <div className="col-xs-6 col-md-4">
            <i className="fa fa-tasks"></i>
            <h3>Acompanhe a execução</h3>
            <p>Você poderá acompanhar toda a exceução do seu móvel, de forma fácil dentro da plataforma.</p>
          </div>
          <div className="col-md-12">
            <h3>Gostaria de receber mais informações?</h3>
            <a
              href="#contact"
              className="btn btn-custom btn-lg page-scroll"
            >Cadastre-se</a>
          </div>
        </div>
      </div>
      <br/>
      <br/>
      <br/>
      <div className="container">
        <div id="marceneiro" className="row">
          <div className="col-md-12">
            <h2>para o marceneiro</h2>
          </div>
          <div className="col-xs-6 col-md-3">
            <i className="fa fa-cogs"></i>
            <h3>Foco na execução</h3>
            <p>O projeto já vem pronto do cliente, e com tudo calculado pela plataforma, se preocupe apenas com a execução</p>
          </div>
          <div className="col-xs-6 col-md-3">
            <i className="fa fa-credit-card"></i>
            <h3>Garantia de pagamento</h3>
            <p>O pagamento será realizado por dentro da plataforma, o marceneiro não precisará se precocupar em cobrar o cliente.</p>
          </div>
          <div className="col-xs-6 col-md-3">
            <i className="fa fa-usd"></i>
            <h3>Gestão Financeira</h3>
            <p>O marceneiro terá controle das suas vendas e dos seus custos de materiais auxiliando no controle financeiro.</p>
          </div>
          <div className="col-xs-6 col-md-3">
            <i className="fa fa-user-plus"></i>
            <h3>Captação de clientes</h3>
            <p>A Montamê é um canal para captação de clientes, reunindo clientes interessados em móveis planejados</p>
          </div>
          <div className="col-md-12">
            <h3>Gostaria de receber mais informações?</h3>
            <a
              href="#contact"
              className="btn btn-custom btn-lg page-scroll"
            >Cadastre-se</a>
          </div>
        </div>
      </div>
      <br/>
      <br/>
      <br/>
    </div>
  );
};
