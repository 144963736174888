import React from "react";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { Services } from "./components/services";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {

  return (
    <div>
      <ToastContainer />
      <Header  />
      <Features />
      <Services  />
      <Team  />
      <Contact  />
    </div>
  );
};

export default App;
