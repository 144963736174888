import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h1>Porque escolher a Montamê</h1>
          <p>
            A Montamê oferece vantagens tanto para o cliente final quanto para o marceneiro, confira abaixo:
          </p>
        </div>
        <div id="cliente" className="row">
          <div className="col-md-12">
            <h2>para o cliente</h2>
          </div>
          <div className="col-xs-6 col-md-3">
            <i className="fa fa-star"></i>
            <div className="service-desc">
              <h3>Encontrar um marceneiro de confiança</h3>
              {/* <p>{d.text}</p> */}
            </div>
          </div>
          <div className="col-xs-6 col-md-3">
            <i className="fa fa-diamond"></i>
            <div className="service-desc">
              <h3>Ter clareza do móvel que chegará na sua residência.</h3>
              {/* <p>{d.text}</p> */}
            </div>
          </div>
          <div className="col-xs-6 col-md-3">
            <i className="fa fa-credit-card"></i>
            <div className="service-desc">
              <h3>facilidade de pagamento</h3>
              {/* <p>{d.text}</p> */}
            </div>
          </div>
          <div className="col-xs-6 col-md-3">
            <i className="fa fa-magic"></i>
            <div className="service-desc">
              <h3>facilidade em criar o móvel para seu lar.</h3>
              {/* <p>{d.text}</p> */}
            </div>
          </div>
          
        </div>
        <div id="cliente" className="row">
          <div className="col-md-12">
            <h2>para o marceneiro</h2>
          </div>
          <div className="col-xs-6 col-md-3">
            <i className="fa fa-user-plus"></i>
            <div className="service-desc">
              <h3>Auxílio em captar clientes</h3>
              {/* <p>{d.text}</p> */}
            </div>
          </div>
          <div className="col-xs-6 col-md-3">
            <i className="fa fa-usd"></i>
            <div className="service-desc">
              <h3>controle financeiro</h3>
              {/* <p>{d.text}</p> */}
            </div>
          </div>
          <div className="col-xs-6 col-md-3">
            <i className="fa fa-list-ol"></i>
            <div className="service-desc">
              <h3>controle de produção</h3>
              {/* <p>{d.text}</p> */}
            </div>
          </div>
          <div className="col-xs-6 col-md-3">
            <i className="fa fa-cogs"></i>
            <div className="service-desc">
              <h3>Facilidade na execução</h3>
              {/* <p>{d.text}</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
