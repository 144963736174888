import React from "react";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h1>Conheça o time</h1>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p> */}
        </div>
        <div id="row">
          <div className="col-md-6 col-sm-6 team">
            <div className="thumbnail">
              {" "}
              <img src="img/team/Andressa.jpg" alt="..." className="team-img" />
              <div className="caption">
                <h4>Andressa Trogello</h4>
                <p>Diretora de negócios</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 team">
            <div className="thumbnail">
              {" "}
              <img src="img/team/Guilherme.png" alt="..." className="team-img" />
              <div className="caption">
                <h4>Guilherme Tessaro</h4>
                <p>Engenheiro de software</p>
              </div>
            </div>
          </div>
        </div>
      </div>-
    </div>
  );
};
